.initial-page {
  display: flex;
}

.initial-page-focus {
  align-items: center;
  justify-content: space-between;
  margin: 0vh auto;
  border-radius: 0.3rem;
  background-color: #fff;
  padding: 0.7rem 1rem;
  width: 98.3vw;
}

.search-container {
  margin-right: 3rem;
  background-color: #fff;
  padding: .70rem 1rem;
  position: relative;
  width: 50%;
  top: 1.51vh;
  height: 87.99vh;
  margin-left: 0.45rem;
  border-radius: 0.3rem;
  transition: width 2s, margin-left 2s, top 2s;
  -webkit-transition: width 2s, margin-left 2s, top 2s;

  .icon {
    margin-left: -12px;
  }

  .ui.active.selection.dropdown {
    padding-left: 26px;
  }

  .ui.search.dropdown.active>input.search,
  .ui.search.dropdown.visible>input.search {
    padding-left: 26px;
  }

  .ui.search.selection.dropdown>input.search {
    padding-left: 26px;
  }

  .ui.fluid.dropdown {
    padding-left: 26px;
  }

  #search-term-input {
    padding-left: 26px;
  }

  .warning-text {
    font-weight: bold;
    opacity: 0.5;
    align-items: flex-end;
  }

  .logo {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    margin-right: 15px;
    margin-bottom: 9px;

    .logo-text {
      font-weight: bold;
      position: relative;
      bottom: -0.2vh;
      opacity: 0.5;
    }

    .logo-img {
      width: 10rem;
    }
  }

  .dropdown-label {
    height: 30px;
    top: 284px;
    left: 49px;
    font-weight: 700;
    line-height: 27px;
  }
}

.search-container-focus {
  width: 98.3vw;
  top: 0vh;
  height: unset;
  padding: 0.7rem 1rem;
  position: relative;
  margin-bottom: 0vh;
  margin-top: 1vh;
  margin-right: auto;
}

.results-container {
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  opacity: 0;
  height: 66vh;
  align-items: center;
  justify-content: space-between;
  margin: 2vh auto;
  border-radius: .3rem;
  background-color: #fff;
  padding: .70rem 1rem;
  width: 98.3vw;
  transition: width 2s, visibility 2s, opacity 2s;
  transition-delay: 1s;
  -webkit-transition: width 2s, visibility 2s, opacity 2s;
  -moz-transition: width 2s, visibility 2s, opacity 2s;
  -o-transition: width 2s, visibility 2s, opacity 2s;
  animation: visible 2s;

  @keyframes visible {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  table {
    width: 99%;
    overflow-y: hidden;

    .cell-with-popup {
      margin-right: 0.8rem;
    }

    div[title="Name"] {
      .icon {
        vertical-align: middle;
        text-align: center;
      }
    }

    tr {
      line-height: 1.25rem;
    }

    td[data-label="Name"] {
      font-weight: 600;
    }

    td[data-label="Location"] {
      div {
        width: 300px;
      }
    }
  }

  .no-results {
    position: relative;
    left: 45%;
    top: 25vh;
    font-weight: bold;
  }
}

.results-container-focus {
  visibility: visible;
  opacity: 1;
  width: 97vw;
}

.one-level-depth {
  padding-left: 15px;
}

.two-level-depth {
  padding-left: 30px;
}

.home-page-message {
  top: 1.51vh;
  margin: 0 auto;
  position: relative;
  transition: width 2s, top 2s;
  font-size: 17px;
  width: 50%;
  height: 87.99vh;
  margin-right: 0.45rem;
}

.add-content-main {
  height: 100%;
}

.add-content-main> :first-child {
  height: 100%;
}

.home-page-message strong {
  font-weight: bolder !important;
}

.flex-div {
  display: flex;
  width: 97vw;
  margin: 1vh auto;
  transition: width 2s, margin-left 2s, top 2s;
  margin-bottom: -0.3rem;
}
.overview {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 5px; //needed for the shadow

  &_container {
    width: 98.3%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: .3rem;
    position: relative;
    padding-top: .1rem;
  }
}
.logo{
  font-weight: bold;
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
}
.logo-img {
  width: 10rem;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: .70rem 1rem;
  position: relative;
  width: 100%;
  transition: width 2s, top 2s;
  margin-left: 0.45rem;
  border-radius: 0.3rem;
  margin-right: 0.45rem;
}

.navbar-records {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 98.3vw;
  transition: width 2s, top 2s;
  margin-bottom: 0vh;
  margin-top: 1vh;
  margin-right: auto;
}

.navbar-div {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 5px;
  margin-top: 1rem;
}